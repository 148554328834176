import * as moment from 'moment';
import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { NestedTreeService } from '../../services/nested-tree.service';
import { ActivityType, IActivityTypeTree } from './activity-type.model';
import { ActivityTypeState, ActivityTypeStore } from './activity-type.store';
import { DateTimeUtilityService } from '../../services/date-time-utility.service';
import { ActivityTypeTimeslotInfoForTable } from '../manage-template-store/manage-template.model';

@Injectable({
    providedIn: 'root'
})
export class ActivityTypeQuery extends QueryEntity<ActivityTypeState> {

    constructor(
        protected store: ActivityTypeStore,
        private readonly nestedTreeService: NestedTreeService,
        protected dateTimeUtilityService: DateTimeUtilityService 
    ) {
        super(store);
    }

    public getActivityTypesSync(): Array<ActivityType> {
        const activityTypes = Object.values(this.getValue().entities);

        return activityTypes.sort((a, b) => a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1);
    }

    public getActivityTypeTimeslotsForSelectedActivityType(selectedActivityTypeId: number, selectedTimeslotIds?: Array<number>, addMode?: boolean): Observable<Array<ActivityTypeTimeslotInfoForTable>> {
        return this.getActivityTypes().pipe(
            map(actTypes => actTypes?.find(actType => actType.id === selectedActivityTypeId)?.timeslots || []),
            map(timeslots => timeslots
                .map(timeslot => ({
                    ...timeslot,
                    startTime: this.dateTimeUtilityService.convertMinutesToHours(timeslot.start),
                    endTime: this.dateTimeUtilityService.convertMinutesToHours(timeslot.end),
                    addToTemplate: selectedTimeslotIds?.includes(timeslot.id) || false,
                    disabledInAddMode: addMode && selectedTimeslotIds?.includes(timeslot.id)
                }))
                .sort((a, b) => a.start > b.start ? 1 : -1)
            )
        );
    }

    public getActivityTypes(): Observable<Array<ActivityType>> {
        return this.selectAll().pipe(map(activityTypes =>
            activityTypes.sort((a, b) => a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1)
        ));
    }

    public getMutableActivityTypes(): Observable<Array<ActivityType>> {
        return this.getActivityTypes().pipe(
            map(activityTypes => {
                return activityTypes.map(activityType => ({ ...activityType, children: [] }));
            })
        );
    }

    public getNestedActvityTypes(): Observable<Array<IActivityTypeTree>> {
        return this.getActivityTypes().pipe(map((activityTypes: Array<IActivityTypeTree>) => {
            const mutableActTypes = activityTypes.map(actType => {
                return { ...actType };
            });

            return this.nestedTreeService.nestArray(mutableActTypes) as Array<IActivityTypeTree>;
        }));
    }

    public getMainActivityTypes(): Observable<Array<ActivityType>> {
        return this.getActivityTypes().pipe(
            map(activityTypes =>
                activityTypes.filter(actTypes => actTypes.parentId === null && actTypes.categoryId !== 3)
            )
        );
    }

    public getMainActivityTypesWithConfiguredTimeslots(): Observable<Array<ActivityType>> {
        return this.getMainActivityTypes().pipe(
            map(activityTypes =>
                activityTypes.filter(actTypes => actTypes.timeslots?.length > 0)
            )
        );
    }

    public isActivityTypeDaymark(id: number): Observable<boolean> {
        return this.getActivityTypes().pipe(
            map(activityTypes => {
                const activityType = activityTypes.find(actTypes => actTypes.id === id);
               
                return activityType !== undefined && activityType.categoryId === 4;
            })
        );
    }

    public getMainActiveActivityTypes(): Observable<Array<ActivityType>> {
        return this.getActivityTypes().pipe(
            map(activityTypes =>
                activityTypes.filter(actTypes => actTypes.parentId === null && actTypes.categoryId !== 3 && (actTypes.validTo === null || new Date(actTypes.validTo) > new Date()))
            )
        );
    }

    public getAllActivityTypeRoots(): Observable<Array<ActivityType>> {
        return this.getActivityTypes().pipe(
            map(activityTypes =>
                activityTypes.filter(actTypes => actTypes.parentId === null)
            )
        );
    }

    //TODO: Check this function for deep children
    public getTotalActivityTypesCount(activityTypes: Array<ActivityType | IActivityTypeTree>): number {
        let count = 0;
    
        for (const activityType of activityTypes) {
          count += this.getActivityTypeCount(activityType);
        }
    
        return count;
    }

    public getSingleConfiguredActivityTypes(): Observable<Array<IActivityTypeTree>> {
        // returns Activities with a resource type configured for the root and no leaf/child activities.
        return this.getActivityTypes().pipe(map((activityTypes: Array<IActivityTypeTree>) => {
            const mutableActTypes = activityTypes.map(unit => {
                return { ...unit };
            });
            const nestedActivityTypes = this.nestedTreeService.nestArray(mutableActTypes) as Array<IActivityTypeTree>;
            const singleConfiguredActivityTypes = nestedActivityTypes.filter(actType => !actType.children || actType.children?.length === 0);

            return singleConfiguredActivityTypes.filter(actType => !!actType.resourceTypeList && actType.resourceTypeList.length > 0);
        }));
    }

    public getEntitiesLoadingState(): Observable<boolean> {
        return this.select(state => state.ui.entitiesLoading);
    }

    public getEntitiesLoadingStateSync(): boolean {
        return this.getValue().ui.entitiesLoading;
    }

    public getValidActivityTypeIdsForCategorySync(categoryId: number): Array<number> {
        const activityTypes = this.getActivityTypesSync();

        return activityTypes
            .filter(at => at.categoryId === categoryId)
            .map(at => at.id);
    }

    public isActivityTypeValid(activityType: ActivityType, showPastActivityTypes: boolean, showFutureActivityTypes: boolean): boolean {
        const showPast = showPastActivityTypes || !activityType.validTo || moment(activityType.validTo).isSameOrAfter(moment());
        const showFuture = showFutureActivityTypes || !activityType.validFrom || moment(activityType.validFrom).isSameOrBefore(moment());

        return showPast && showFuture;
    }

    public getActivityTypeMap(): Map<number, ActivityType> {
        return this.getValue().activityTypeMap;
    }

    public getFullActivityStructureAndSortByRootIdSync(rootId: number): Array<ActivityType> {
        const activityTypeStructureAndSorting = this.getValue().activityTypeStructureAndSorting;

        return activityTypeStructureAndSorting.get(rootId);
    }

    public getFullActivityStructureAndSortByRootId(rootId: number): Observable<Array<ActivityType>> {
        const activityTypeStructureAndSorting = this.select(state => state.activityTypeStructureAndSorting);

        return activityTypeStructureAndSorting.pipe(
            map(activityTypeMap => activityTypeMap.get(rootId) || [])
        );
    }

    public getRootParentIdOfActivityTypeById(activityTypeId: number): number {
        const allActivityTypes = this.getActivityTypesSync();
        const activtyType = allActivityTypes?.find(a => a.id === activityTypeId);

        if (!activtyType) {
            return;
        }

        const findRootParentId = (activityType: ActivityType, activityTypes: Array<ActivityType>): number => {
            if (activityType.parentId === null) {
                return activityType.id;
            }

            const parentActivityType = this.getActivityTypeMap().get(activityType.parentId);

            if (!parentActivityType) {
                return;
            }
          
            return findRootParentId(parentActivityType, activityTypes);
        }

        return findRootParentId(activtyType, allActivityTypes);
    }

    private getActivityTypeCount(activityType: any): number {
        let count = 1; // Include the activity type itself
    
        if (activityType?.children && activityType?.children.length > 0) {
            for (const child of activityType.children) {
                count += this.getActivityTypeCount(child);
            }
        }
    
        return count;
    }
}
