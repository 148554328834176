import { ChangeDetectionStrategy, Component, OnInit, ViewChild, inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { SocaWebUiCommonModule, cache } from '@ortec/soca-web-ui';
import { BehaviorSubject, Observable, Subject, Subscription, filter, first, mergeMap, switchMap } from 'rxjs';
import { Memoized } from '@ortec/utilities/core';
import { MatTableDataSource } from '@angular/material/table';
import { BolPopoverModule } from '@ortec/bolster/popover';
import { MatSelect } from '@angular/material/select';

import { AdtPrefferedResourceSelectDialogComponent, IPreferredResourceSelectCloseDialogData, IPreferredResourceSelectDialogData } from '../adt-preferred-resource-select-dialog/adt-preferred-resource-select-dialog.component';
import { MultiselectTreeComponent } from 'src/app/shared/components/inputs/multiselect-tree/multiselect-tree.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { EditTemplatesManagementQuery } from '../../store/edit-template-management.query';
import { ManageTimeslotQuery } from 'src/app/shared/stores/manage-timeslot-store/manage-timeslot.query';
import { AdtHelperService } from '../adt-helper/adt-helper.service';
import { ResourceRequestParameters } from 'src/app/shared/stores/resource-store/resource.model';
import { ResourceService } from 'src/app/shared/stores/resource-store/resource.service';
import { ResourceQuery } from 'src/app/shared/stores/resource-store/resource.query';
import { ActivityTypeTimeslot } from 'src/app/shared/stores/manage-template-store/manage-template.model';
import { OrganizationUnitQuery } from 'src/app/shared/stores/organization-unit-store/organization-unit.query';
import { PreferredResourcesInfoForTable, PreferredResourcesQuery, PreferredResourcesService } from 'src/app/shared/stores/preferred-resources-store';

export interface IPreferredResourceDialogData {
    selectedTemplateId: number,
    activityTypeTimeslot: ActivityTypeTimeslot
}

@Component({
    standalone: true,
    selector: 'app-adt-preferred-resource-dialog',
    templateUrl: './adt-preferred-resource-dialog.component.html',
    styleUrls: ['./adt-preferred-resource-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,

        MatButtonModule,
        MatIconModule,
        TranslateModule,
        MatIconModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatDialogModule,
        SocaWebUiCommonModule,
        SharedModule,
        BolPopoverModule,

        MultiselectTreeComponent,
    ],
})
export class AdtPreferredResourceDialogComponent implements OnInit {
    @ViewChild('weekSelect') public weekSelect!: MatSelect;
    @ViewChild('daySelect') public daySelect!: MatSelect;

    public weeks = [];
    public days = [];
    public displayedColumns: Array<string> = ['shortName', 'displayName', 'preferredResources'];
    public dataSource = new MatTableDataSource<any>([]);

    // TODO: implement logic for missing preferred resources
    public missingPreferredResourcesSubject = new BehaviorSubject<boolean>(true);

    private activityTypeTimeslotId?: number;
    private templateId?: number;
    private activityTypeId?: number;
    private onSelectResourcesClickedSubject = new Subject<PreferredResourcesInfoForTable>();
    private readonly subscriptions = new Subscription();

    private readonly resourceService = inject(ResourceService);
    private readonly organizationUnitQuery = inject(OrganizationUnitQuery);
    private readonly dialogService = inject(MatDialog);
    private readonly resourceQuery = inject(ResourceQuery);
    private readonly adtHelperService = inject(AdtHelperService);
    private readonly dialogRef = inject<MatDialogRef<IPreferredResourceDialogData>>(MatDialogRef);
    private readonly editTemplatesManagementQuery = inject(EditTemplatesManagementQuery);
    private readonly manageTimeslotQuery = inject(ManageTimeslotQuery);
    private readonly preferredResourcesService = inject(PreferredResourcesService);
    private readonly preferredResourcesQuery = inject(PreferredResourcesQuery);
    private readonly dialogData = inject<IPreferredResourceDialogData>(MAT_DIALOG_DATA);

    public ngOnInit(): void {
        this.setInitialData();
        this.subscriptions.add(this.updatePreferredResourcesForCurrentWeekDay());
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public openedWeekSelect(state: boolean): void {
        if (!state) {
            const previousWeekValue = this.preferredResourcesQuery.getCurrentWeekSync();

            if (previousWeekValue !== this.weekSelect.value) {
                this.preferredResourcesService.updateCurrentWeek(this.weekSelect.value);
                this.getPreferredResourcesForCurrentDay();
            }
        }
    }

    public openedDaySelect(state: boolean): void {
        if (!state) {
            const previousDayValue = this.preferredResourcesQuery.getCurrentDaySync();

            if (previousDayValue !== this.daySelect.value) {
                this.preferredResourcesService.updateCurrentDay(this.daySelect.value);
                this.getPreferredResourcesForCurrentDay();
            }
        }
    }

    public onSelectResources(activityType: PreferredResourcesInfoForTable): void {
        this.onSelectResourcesClickedSubject.next(activityType);
    }

    public onCloseModal(): void {
        this.dialogRef.close();
    }

    public onCancel(): void {
        this.dialogRef.close();
    }

    @Memoized private get dialogResponse$(): Observable<{ data: IPreferredResourceSelectCloseDialogData }> {
        return this.onSelectResourcesClickedSubject.pipe(
            mergeMap(activityType => 
                this.dialogService.open(AdtPrefferedResourceSelectDialogComponent, {
                    data: {
                        activityType: activityType,
                        demandAmount: this.preferredResourcesQuery.getDemandForCurrentWeekDaySync(),
                        organizationUnits: this.organizationUnitQuery.getOrganizationUnitsForActivityTypesSync(),
                        resources: this.resourceQuery.getAll(),
                        
                    } as IPreferredResourceSelectDialogData,
                    width: '600px',
                    autoFocus: false,
                }).afterClosed()
            )
        );
    }

    @Memoized public get activityTypeNameAndTimeslotPeriod$(): Observable<string> {
        return this.manageTimeslotQuery.getActivityTypeNameAndTimeslotPeriodByActivityTypeTimeslotId(this.activityTypeTimeslotId).pipe(cache());
    }

    @Memoized public get weekSelect$(): Observable<number> {
        return this.preferredResourcesQuery.getCurrentWeek().pipe(cache());
    }

    @Memoized public get daySelect$(): Observable<number> {
        return this.preferredResourcesQuery.getCurrentDay().pipe(cache());
    }

    @Memoized public get initialLoadingFinished$(): Observable<boolean> {
        return this.resourceQuery.getEntitiesLoading().pipe(cache());
    }

    @Memoized public get showSpinner$(): Observable<boolean> {
        return this.preferredResourcesQuery.getEntitiesLoadingState().pipe(cache());
    }

    @Memoized public get demandAmount$(): Observable<number> {
        return this.preferredResourcesQuery.getDemandForCurrentWeekDay().pipe(cache());
    }

    @Memoized public get missingPreferredResources$(): Observable<boolean> {
        return this.missingPreferredResourcesSubject.asObservable().pipe(cache());
    }
    
    @Memoized public get activityTypes$(): Observable<Array<PreferredResourcesInfoForTable>> {
        return this.preferredResourcesQuery.getActivityTypesForCurrentWeekDay(this.activityTypeId).pipe(cache());
    }

    private setInitialData(): void {
        this.activityTypeTimeslotId = this.dialogData.activityTypeTimeslot.id;
        this.activityTypeId = this.dialogData.activityTypeTimeslot.activityTypeInfo.id;
        this.templateId = this.dialogData.selectedTemplateId;
    
        const getResourceParameters: ResourceRequestParameters = {
            includeResourceTypeMemberships: true,
            includeOrganizationUnitMemberships: true
        };

        this.resourceService.getResources(getResourceParameters).pipe(first()).subscribe();
        this.getPreferredResourcesForCurrentDay();
        
        this.days = this.adtHelperService.getDayOptions();
        this.weeks = this.adtHelperService.getWeekOptions(this.editTemplatesManagementQuery.getMaxWeekNumberSync());
    }
    
    private getPreferredResourcesForCurrentDay(): void {
        this.preferredResourcesService.getPreferredResourcesForCurrentWeekDay(this.templateId, this.activityTypeTimeslotId).pipe(first()).subscribe();
    }

    private updatePreferredResourcesForCurrentWeekDay(): Subscription {
        return this.dialogResponse$.pipe(
            filter((dialogData) => !!dialogData),
            switchMap(dialogData =>
                this.preferredResourcesService.updatePreferredResourcesForCurrentWeekDay(
                    this.templateId,
                    this.activityTypeTimeslotId,
                    dialogData.data.selectedResourceIds,
                    dialogData.data.activityTypeId
                ).pipe(first())
            )
        ).subscribe();
    }
}