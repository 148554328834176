<div class="bol-modal__header">
    <span class="modal-title" translate>ACTIVITY-DEMAND.EDIT-TEMPLATES.TIMESLOTS.DIALOG-TITLE </span>
    <div class="bol-modal__close" (click)="onCloseModal()">
      <i class="material-icons">close</i>
    </div>
</div>
  
<mat-dialog-content>
    <div>
        <div class="filters-activity-types">
            <ng-container *ngIf="mode === manageMode.ADD; else editContent">
                <app-multiselect-tree
                    searchFieldPlaceholder="SEARCH.ORGANIZATION-UNIT"
                    multiselectTreePlaceholder="ENTITY.ORGANIZATION-UNIT"
                    class="organization-unit-filter-activity-type"
                    [entities]="organizationUnits$ | async"
                    [required]="false"
                    [showSelectAll]="true"
                    (selectedEntityIdsChanged)="updateActivityTypeTableBasedOnOrganizationUnits($event)"
                    data-cy="activityTypeDialogOrganizationUnitsFilter"
                ></app-multiselect-tree>
            
                <app-single-select-table
                    entityName="ENTITY.ACTIVITY-TYPE"
                    singleSelectTablePlaceholder="Activity type"
                    [columnDefinition]="columnDefinition"
                    [entities]="activityTypesForFiltering$ | async"
                    [entityProperty]="'shortName'"
                    [searchProperties]="searchProperties"
                    [selectedEntityId]="selectedActivityTypeId$ | async"
                    (selectedEntityIdChanged)="onSelectedActivityTypeChange($event)">
                </app-single-select-table>
            </ng-container>
            
            <ng-template #editContent>
                <div *ngIf="selectedActivityType$ | async as selectedActivityType" style="display: flex;">
                    <div [style.background-color]="'#' + selectedActivityType.backColor" class="short-name-div">
                        {{ selectedActivityType.shortName }}
                    </div>
                    <div class="display-name-div">
                        {{ selectedActivityType.displayName }}
                    </div>
                </div>
            </ng-template>
        </div>

        <div class="timeslots-table">
            <table mat-table class="bol-table bol-table-striped-odd" [dataSource]="timeslotsOfActivityType$ | async">
    
                <ng-container matColumnDef="startTime">
                    <th mat-header-cell *matHeaderCellDef translate>COMMON.START-TIME</th>
                    <td mat-cell *matCellDef="let timeslot">{{timeslot.startTime}}</td>
                </ng-container>
    
                <ng-container matColumnDef="endTime">
                    <th mat-header-cell *matHeaderCellDef translate>COMMON.END-TIME</th>
                    <td mat-cell *matCellDef="let timeslot">{{timeslot.endTime}}</td>
                </ng-container>

                <ng-container matColumnDef="addToTemplate">
                    <th *matHeaderCellDef mat-header-cell class="adt-checkbox-column" translate>ACTIVITY-DEMAND.EDIT-TEMPLATES.ADD-TO-TEMPLATE</th>
                    <td *matCellDef="let timeslot" mat-cell class="adt-checkbox-column">
                        <mat-checkbox  
                            class="bol-checkbox"
                            [checked]="timeslot.addToTemplate"
                            [disabled]="timeslot.disabledInAddMode"
                            (change)="onUpdateTimeslot($event, timeslot)"
                            data-cy="addToTemplate">
                        </mat-checkbox>
                    </td>
                </ng-container>
    
                <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
                <tr *matRowDef="let row; columns: displayedColumns;" mat-row tabindex="0"></tr>
                <tr *matNoDataRow class="bol-table-empty-state">
                    <td [attr.colspan]="displayedColumns.length" translate>ACTIVITY-DEMAND.EDIT-TEMPLATES.TIMESLOTS.SELECT-ACTIVITY</td>
                </tr>
            </table>
        </div>
        <div class="callout-message">
            <bol-callout *ngIf="isDaymark$ | async">{{ "ACTIVITY-DEMAND.EDIT-TEMPLATES.TIMESLOTS.DAYPART-CALLOUT" | translate }}</bol-callout>
        </div>
        <div class="callout-message">
            <bol-callout *ngIf="showAllDayMarksAddedCallout$ | async">{{ "ACTIVITY-DEMAND.EDIT-TEMPLATES.TIMESLOTS.ALL-TIMESLOTS-ADDED" | translate }}</bol-callout>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <div class="dialog-actions-buttons">
        <button 
            mat-button 
            (click)="onCancel()"
            data-cy="activityTypeDialogConfirmButton"
        >
            <span translate>ACTION.CANCEL</span>
        </button>
    </div>
    <div class="dialog-actions-buttons">
        <button 
            mat-button 
            class="bol-button-solid"
            [disabled]="disableConfirmButton$ | async"
            (click)="onConfirm()"
            data-cy="activityTypeDialogConfirmButton"
        >
            <span translate>ACTION.CONFIRM</span>
        </button>
    </div>
</mat-dialog-actions>