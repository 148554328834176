import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { SocaWebUiCommonModule, cache, filterUndefined } from '@ortec/soca-web-ui';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { Memoized } from '@ortec/utilities/core';
import { BolCalloutModule } from '@ortec/bolster/callout';
import { BolPopoverModule } from '@ortec/bolster/popover';

import { IOrganizationUnitTree } from 'src/app/shared/stores/organization-unit-store/organization-unit.model';
import { MultiselectTreeComponent } from 'src/app/shared/components/inputs/multiselect-tree/multiselect-tree.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { Resource } from 'src/app/shared/stores/resource-store/resource.model';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { PreferredResourcesInfoForTable } from 'src/app/shared/stores/preferred-resources-store';

export interface IPreferredResourceSelectDialogData {
    activityType: PreferredResourcesInfoForTable;
    demandAmount: number;
    organizationUnits: Array<IOrganizationUnitTree>;
    resources: Array<Resource>;
}

export interface IPreferredResourceSelectCloseDialogData {
    selectedResourceIds: Array<number>;
    activityTypeId: number;
}

@Component({
    standalone: true,
    selector: 'app-adt-preferred-resource-dialog',
    templateUrl: './adt-preferred-resource-select-dialog.component.html',
    styleUrls: ['./adt-preferred-resource-select-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,

        MatButtonModule,
        MatIconModule,
        TranslateModule,
        MatIconModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatDialogModule,
        SocaWebUiCommonModule,
        SharedModule,
        BolCalloutModule,
        BolPopoverModule,

        MultiselectTreeComponent,
    ],
})
export class AdtPrefferedResourceSelectDialogComponent implements OnInit {
    public selectedOrganizationUnitIdsSubject = new BehaviorSubject<Array<number>>([]);
    public selectedPreferredResourceIdsSubject = new BehaviorSubject<Array<number>>([]);
    
    public allResources?: Array<Resource>;
    public organizationUnitsForFiltering?: Array<IOrganizationUnitTree>;
    public demandAmount?: number;
    public activityType?: PreferredResourcesInfoForTable;

    private originalResourceSelection?: Array<number>;
    private readonly dialogRef = inject<MatDialogRef<IPreferredResourceSelectDialogData>>(MatDialogRef);
    private readonly dialogData = inject<IPreferredResourceSelectDialogData>(MAT_DIALOG_DATA);
    private readonly utilsService = inject(UtilsService);

    public ngOnInit(): void {
        this.setInitialData();
    }

    public onCloseModal(): void {
        this.dialogRef.close();
    }

    public onCancel(): void {
        this.dialogRef.close();
    }

    public onConfirm(): void {
        // Note: if the user hasn't actually changed anything, consider "save" as a "cancel", so we don't trigger an unnecessary API call
        if (this.utilsService.arraysEqual(this.originalResourceSelection, this.selectedPreferredResourceIdsSubject.value)) {
            this.dialogRef.close();

            return;
        }

        this.dialogRef.close({
            data: {
                selectedResourceIds: this.selectedPreferredResourceIdsSubject.value,
                activityTypeId: this.activityType.id
            } as IPreferredResourceSelectCloseDialogData
        });
    }

    public onFilteredOrganizationsChanged(organizationIds: Array<number>): void {
        this.selectedOrganizationUnitIdsSubject.next(organizationIds);
    }

    public onPreferredResourcesChanged(resourceIds: Array<number>): void {
        this.selectedPreferredResourceIdsSubject.next(resourceIds);
    }

    @Memoized public get preselectedOrganizationUnits$(): Observable<Array<number>> {
        return this.selectedOrganizationUnitIdsSubject.asObservable().pipe(cache());
    }

    @Memoized public get demandExceeded$(): Observable<boolean> {
        return this.preselectedPreferredResources$.pipe(map(selectedId => selectedId.length > this.demandAmount)).pipe(cache());
    }

    // TODO: with the current logic, resources are deselected when the organization units are changed 
    // to something that doesn't contain the selected organization unit
    // discuss the desired behavior for this component
    @Memoized public get validResources$(): Observable<Array<Resource>> {
        return this.preselectedOrganizationUnits$.pipe(
            filterUndefined(),
            map((filteredOrganizationIds) => 
                this.allResources.filter(resource => 
                    resource.resourceTypeMemberships.some(membership => this.activityType.resourceTypeIds.includes(membership.resourceTypeId)) &&
                    (filteredOrganizationIds.length === 0 || resource.organizationUnitMemberships.some(membership => filteredOrganizationIds.includes(membership.organizationUnitId)))
                ).map(resource => ({...resource}))
            ),
            cache()
        );
    }

    @Memoized public get preselectedPreferredResources$(): Observable<Array<number>> {
        return this.selectedPreferredResourceIdsSubject.asObservable().pipe(cache());
    }

    private setInitialData(): void {
        this.organizationUnitsForFiltering = this.dialogData.organizationUnits;
        this.allResources = this.dialogData.resources;
        this.demandAmount = this.dialogData.demandAmount;
        this.activityType = this.dialogData.activityType;
        this.originalResourceSelection = this.activityType.preferredResourceIds;

        this.selectedPreferredResourceIdsSubject.next(this.activityType.preferredResourceIds);
    }
}