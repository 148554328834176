<ng-container *ngIf="(initialLoadingFinished$ | async) === false">
    <div class="bol-modal__header">
        <span class="modal-title">{{ "ACTIVITY-DEMAND.EDIT-TEMPLATES.PREFERRED-RESOURCE.TITLE" | translate }} {{ activityTypeNameAndTimeslotPeriod$ | async }}</span>
        <div class="bol-modal__close" (click)="onCloseModal()">
        <i class="material-icons">close</i>
        </div>
    </div>
    
    <mat-dialog-content>
        <div>
            <div class="top-filters">
                <div class="week-day-container">
                    <div class="week-input">
                        <mat-form-field class="bol-form-field default-mat-form-field" appearance="fill" data-cy="weekField">
                            <mat-label translate>TIMEDURATION.WEEK</mat-label>
                            <mat-select
                                [value]="weekSelect$ | async"
                                (openedChange)="openedWeekSelect($event)"
                                #weekSelect
                                data-cy="weekSelect"
                            >
                            <mat-option *ngFor="let week of weeks" [value]="week.value">{{ week.type }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="day-input">
                        <mat-form-field class="bol-form-field default-mat-form-field" appearance="fill" data-cy="dayField">
                            <mat-label translate>TIMEDURATION.DAY</mat-label>
                            <mat-select
                                [value]="daySelect$ | async"
                                (openedChange)="openedDaySelect($event)"
                                #daySelect
                                data-cy="daySelect"
                            >
                            <mat-option *ngFor="let day of days" [value]="day.value">{{ day.type | translate }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="info-icon-sign" *ngIf="missingPreferredResources$ | async" [bolPopoverHover]="popoverHover" data-cy="missingResourcesInfoIcon">
                        <mat-icon>info</mat-icon>
                    </div>

                    <ng-template #popoverHover>
                        <!-- we need to add the logic to display Affected weeks: Week 1, Week 2, Week 3, etc. -->
                        <!-- Affected days for week {{current week number}}: Mon, Tue, Wed, etc. -->
                        <div class="popover-hover"></div>
                      </ng-template>
                </div>

                <div class="demand-amount-info-message" data-cy="demandAmount">
                    <h4 class="bol-text bol-text-medium">{{ "ACTIVITY-DEMAND.EDIT-TEMPLATES.PREFERRED-RESOURCE.DEMAND-AMOUNT" | translate}}{{ demandAmount$ | async }}</h4>
                </div>

            </div>

            <div class="activity-types-table">
                <table 
                    mat-table 
                    class="bol-table bol-table-bordered-rows preferred-resources-table"
                    [dataSource]="activityTypes$ | async" 
                >
                    <ng-container matColumnDef="shortName">
                        <th *matHeaderCellDef mat-header-cell translate class="column_shortname">ENTITY.ACTIVITY-TYPE</th>
                        <td *matCellDef="let activityType" mat-cell>
                            <div class="column_shortname-cell"
                            [ngStyle]="activityType.nameStyling">
                                {{ activityType.shortName }}
                            </div>
                        </td>
                    </ng-container>
        
                    <ng-container matColumnDef="displayName">
                        <th *matHeaderCellDef mat-header-cell translate class="column_displayname">COMMON.DISPLAY-NAME</th>
                        <td *matCellDef="let activityType" mat-cell>{{ activityType.displayName }}</td>
                    </ng-container>

                    <ng-container matColumnDef="preferredResources">
                        <th *matHeaderCellDef mat-header-cell translate>ACTIVITY-DEMAND.EDIT-TEMPLATES.PREFERRED-RESOURCE.PREFERRED-RESOURCES</th>
                        <td *matCellDef="let activityType" mat-cell class="column_resources-cell-container">
                            <ng-container *ngIf="!activityType.hasChildren">
                                <ng-container *ngIf="activityType.preferredResourceNames !== ''; else resourceButton">
                                    <div class="column_resources-cell">
                                        <div class="column_resources-cell-inner" [bolPopoverOverflow]="popoverHover">
                                            <ng-template #popoverHover> {{ activityType.preferredResourceNames }} </ng-template>
                                            {{ activityType.preferredResourceNames }}
                                        </div>
                                        <button 
                                            mat-button 
                                            class="bol-button-text icon-only column_resources-button"
                                            (click)="onSelectResources(activityType)"
                                            data-cy="selectPreferredResourcesButton"
                                        >
                                            <mat-icon>edit</mat-icon>
                                        </button>
                                    </div>
                                </ng-container>
                                <ng-template #resourceButton>
                                    <button 
                                        mat-button 
                                        class="bol-button-text"
                                        (click)="onSelectResources(activityType)"
                                        data-cy="selectPreferredResourcesButton"
                                    >
                                        <mat-icon>add</mat-icon>
                                        <span translate>ACTIVITY-DEMAND.EDIT-TEMPLATES.PREFERRED-RESOURCE.SELECT-RESOURCES</span>
                                    </button>
                                </ng-template>
                            </ng-container>
                        </td>
                    </ng-container>
        
                    <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
                    <tr *matRowDef="let row; columns: displayedColumns;" tabindex="0" mat-row></tr>
                </table>
            </div>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions>
        <div class="dialog-actions-buttons">
            <button 
                mat-button 
                (click)="onCancel()"
                data-cy="preferredResourceDialogConfirmButton"
            >
                <span translate>ACTION.CLOSE</span>
            </button>
        </div>
    </mat-dialog-actions>
</ng-container>

<div *ngIf="(initialLoadingFinished$ | async) === true || (showSpinner$ | async) === true" class="overlay-spinner">
    <mat-spinner diameter="100"></mat-spinner>
</div>
