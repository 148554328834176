import { ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Memoized } from '@ortec/utilities/core';
import { Observable, Subject, Subscription, combineLatest, filter, first, map, mergeMap, startWith, switchMap, tap } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { cache, combineSubscriptions } from '@ortec/utilities/rxjs';
import { FormControl } from '@angular/forms';

import { AutosaveService } from 'src/app/shared/services/autosave.service';
import { ManageTemplateQuery } from 'src/app/shared/stores/manage-template-store/manage-template.query';
import { ManageCounterService } from 'src/app/shared/stores/manage-counter-store/manage-counter.service';
import { ManageTemplateCounterService } from 'src/app/shared/stores/manage-template-counter-store/manage-template-counter.service';

import { AdtCountersDialogComponent, ICountersDialogData } from '../adt-counters-dialog/adt-counters-dialog.component';
import { AdtCounter } from 'src/app/shared/stores/manage-template-store/manage-template.model';
import { ManageTemplateCounterQuery } from 'src/app/shared/stores/manage-template-counter-store/manage-template-counter.query';
import { EditTemplatesManagementQuery, EditTemplatesManagementService, WeekValues } from '../../store';

@Component({
    selector: 'app-adt-counter-table',
    templateUrl: './adt-counter-table.component.html',
    styleUrls: ['./adt-counter-table.component.scss'],
    providers: [AutosaveService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdtCounterTableComponent implements OnInit, OnDestroy {
    public searchTerm = new FormControl('');
    public counters: Array<AdtCounter> = [];
    public currentTemplateId?: number;

    private onAddCountersClickedSubject = new Subject<void>();
    private readonly subscriptions = new Subscription();
    
    private readonly editTemplatesManagementService = inject(EditTemplatesManagementService);
    private readonly editTemplatesManagementQuery = inject(EditTemplatesManagementQuery);
    private readonly manageTemplateQuery = inject(ManageTemplateQuery);
    private readonly manageTemplateCounterService = inject(ManageTemplateCounterService);
    private readonly manageTemplateCounterQuery = inject(ManageTemplateCounterQuery);
    private readonly manageCounterService = inject(ManageCounterService);
    private readonly dialogService = inject(MatDialog);

    public ngOnInit(): void {
        this.manageCounterService.getCounters().pipe(first()).subscribe();
        this.currentTemplateId = this.editTemplatesManagementQuery.getCurrentTemplateIdSync();

        this.subscriptions.add(
            combineSubscriptions(
                this.getFilteredCountersValuesBasedOnSearching(),
                this.updateCounters()
            )
        );
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
    
    public onAddCounter(): void {
        this.onAddCountersClickedSubject.next();
    }

    public onRefreshCounters(): void {
        this.manageTemplateCounterService.getCounters(this.currentTemplateId).pipe(first()).subscribe();
    }

    @Memoized private get addCounterDialogResponse$(): Observable<{ data: ICountersDialogData }> {
        return this.onAddCountersClickedSubject.pipe(
            mergeMap(() => 
                this.dialogService.open(AdtCountersDialogComponent, { 
                    width: '600px', 
                    autoFocus: false 
                }).afterClosed()
            )
        );
    }

    @Memoized public get counterRows$(): Observable<Array<AdtCounter>> {
        return this.editTemplatesManagementQuery.getCountersEntities().pipe(
            cache()
        );
    }

    @Memoized public get currentWeekValues$(): Observable<Array<WeekValues>> {
        return combineLatest({
            currentWeek: this.editTemplatesManagementQuery.getCurrentWeekNumber(),
            counters: this.counterRows$,
        }).pipe(
            map(({currentWeek, counters}) => {
                return counters.map(counter => {
                    if (counter.weeksDemand === undefined || counter.weeksDemand === null) {
                        return null;
                    }
                    return counter?.weeksDemand[currentWeek - 1];
                })
            }),
            cache()
        );
    }

    @Memoized public get initialLoadingFinished$(): Observable<boolean> {
        return combineLatest({
            loading: this.manageTemplateQuery.getEntitiesLoadingState(),
            countersLoading: this.manageTemplateCounterQuery.getEntitiesLoadingState(),
        }).pipe(
            filter(({loading, countersLoading}) => {
                return !loading && !countersLoading;
            }),
            map(() => true),
            first(),
            startWith(false),
            cache()
        );
    }

    @Memoized public get countersLoading$(): Observable<boolean> {
        return this.manageTemplateCounterQuery.getEntitiesLoadingState().pipe(
            cache()
        );
    }

    @Memoized public get calculatingCountersInProgress$(): Observable<boolean> {
        return this.manageTemplateCounterQuery.getCalculatingCountersInProgressState().pipe(
            cache()
        );
    }

    @Memoized public get showCountersRefreshButton$(): Observable<boolean> {
        // We need to show the refresh button when:
        // - A new timeslot is added
        // - A timeslot is removed
        // - A timeslot is edited (the count per days)
        // Set to false after reloading
        return this.manageTemplateCounterQuery.getReloadCountersState();
    }

    private getFilteredCountersValuesBasedOnSearching(): Subscription {
        return this.searchTerm.valueChanges.pipe(
            startWith(''),
            map((searchTerm) => {
                if (this.searchTerm.valid) {
                    this.editTemplatesManagementService.updateSearchCountersValue(searchTerm);
                }
            })
        ).subscribe()
    }

    private updateCounters(): Subscription {
        return this.addCounterDialogResponse$.pipe(
            filter(dialogData => !!dialogData),
            switchMap(dialogData => 
                this.manageTemplateCounterService.updateActivityDemandCounters(dialogData.data.selectedCounterIds, this.currentTemplateId).pipe(first())
            )
        ).subscribe();
    }
}