<div class="bol-modal__header">
    <span class="modal-title" translate>ACTIVITY-DEMAND.EDIT-TEMPLATES.COUNTERS.DIALOG-TITLE</span>
    <div class="bol-modal__close" (click)="onCloseModal()">
      <i class="material-icons">close</i>
    </div>
</div>
  
<mat-dialog-content>
    <div>
        <div class="search-counter">
            <mat-form-field class="bol-form-field adt-table__search">
                <input matInput [formControl]="searchTerm" placeholder="{{'ACTIVITY-DEMAND.SEARCH-COUNTERS' | translate}}" autocomplete="off" />
            </mat-form-field>
        </div>

        <div class="counters-table">
            <table mat-table [dataSource]="counters$ | async" class="bol-table bol-table-striped-odd">
    
                <ng-container matColumnDef="displayName">
                    <th mat-header-cell *matHeaderCellDef translate>COMMON.DISPLAY-NAME</th>
                    <td mat-cell *matCellDef="let counter">{{counter.displayName}}</td>
                </ng-container>
    
                <ng-container matColumnDef="addToTemplate">
                    <th mat-header-cell *matHeaderCellDef translate>ACTIVITY-DEMAND.EDIT-TEMPLATES.ADD-TO-TEMPLATE</th>
                    <td mat-cell *matCellDef="let counter">
                        <mat-checkbox  
                            class="bol-checkbox"
                            [disabled]="counter.disabledInAddMode"
                            [checked]="counter.addToTemplate"
                            (change)="onUpdateCounter($event, counter)"
                            data-cy="addToTemplate">
                        </mat-checkbox>
                    </td>
                </ng-container>
    
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" tabindex="0"></tr>
                <tr *matNoDataRow class="bol-table-empty-state">
                    <ng-container *ngIf="(counters$ | async)?.length === 0">
                        <td [attr.colspan]="displayedColumns.length" translate>ACTIVITY-DEMAND.EDIT-TEMPLATES.NO-COUNTERS-AVAILABLE</td>
                    </ng-container>
                </tr>
            </table>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <div class="dialog-actions-buttons">
        <button 
        mat-button 
        data-cy="activityTypeDialogConfirmButton"
        (click)="onCancel()">
            <span translate>ACTION.CANCEL</span>
        </button>
    </div>
    <div class="dialog-actions-buttons">
        <button 
        mat-button 
        data-cy="activityTypeDialogConfirmButton"
        class="bol-button-solid"
        (click)="onConfirm()">
            <span translate>ACTION.CONFIRM</span>
        </button>
    </div>
</mat-dialog-actions>