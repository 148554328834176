<div *ngIf="(initialLoadingFinished$ | async) === true" class="adt-table">
    <div class="adt-table__header">
        <div class="adt-table__section-info">
            <div class="adt-table__header-bar">
                <button 
                    mat-button 
                    class="bol-button-solid"
                    (click)="onAddActivityTypeClicked()"
                >
                    <mat-icon>add</mat-icon>
                    <span translate>ACTION.ADD</span>
                </button>
        
                <mat-form-field class="bol-form-field adt-table__search">
                    <input 
                        matInput 
                        placeholder="{{'ACTIVITY-DEMAND.SEARCH-ACTIVITIES' | translate}}" 
                        autocomplete="off" 
                        [formControl]="searchTerm" />
                </mat-form-field>
            </div>

            <div class="adt-table__header-bar bol-text-medium">
                <div translate class="flex2">ENTITY.ACTIVITY-TYPE</div>
                <div translate class="flex1">COMMON.START-TIME</div>
                <div translate class="flex1">COMMON.END-TIME</div>
                <div translate class="flex1 distribtion-cell">ACTIVITY-DEMAND.DISTRIBUTION-TOTAL</div>
            </div>
        </div>

        <div class="adt-table__section-week">
            <div class="adt-table__header-bar">
                <div *ngIf="(maxWeeks$ | async) > 1" class="adt-table__week-wrapper">
                    <div class="adt-table__week-navigation">
                        <button 
                            mat-button 
                            class="bol-button-text bol-gray icon-only"
                            [disabled]="(currentWeek$ | async) === 1"
                            (click)="onNavigateToPastWeek()"
                        >
                            <mat-icon>keyboard_arrow_left</mat-icon>
                        </button>
    
                        {{ "TIMEDURATION.WEEK" | translate}} {{currentWeek$ | async}} / {{maxWeeks$ | async}}
                        
                        <button 
                            mat-button 
                            class="bol-button-text bol-gray icon-only"
                            [disabled]="(currentWeek$ | async) === (maxWeeks$ | async)"
                            (click)="onNavigateToNextWeek()"
                        >
                            <mat-icon>keyboard_arrow_right</mat-icon>
                        </button>
                    </div>
    
                    <button 
                        mat-button 
                        class="bol-button-solid"
                        (click)="onCopyWeeks()"
                    >
                        <mat-icon>content_copy</mat-icon>
                        <span translate>ACTIVITY-DEMAND.EDIT-TEMPLATES.COPY-WEEKS</span>
                    </button>
                </div>
            </div>
            
            <div class="adt-table__header-bar bol-text-medium">
                <div translate class="flex1">WEEK.MON</div>
                <div translate class="flex1">WEEK.TUE</div>
                <div translate class="flex1">WEEK.WED</div>
                <div translate class="flex1">WEEK.THU</div>
                <div translate class="flex1">WEEK.FRI</div>
                <div translate class="flex1">WEEK.SAT</div>
                <div translate class="flex1">WEEK.SUN</div>
                <div translate class="flex1">TOTAL.WEEK</div>
            </div>
        </div>

        <div class="adt-table__section-total">
            <div class="adt-table__header-bar"></div>
            <div class="adt-table__header-bar bol-text-medium">
                <div translate class="flex1">TOTAL.PERIOD</div>
                <div class="flex1"></div>
            </div>
        </div>
    </div>
    <form [formGroup]="formArray">
        <div *ngIf="activityTypeTimeslotsRows$ | async as activityTypeTimeslotsRows" class="adt-table__content">
            <div class="adt-table__section-info">
                <div *ngFor="let group of formArray.controls; let i = index" [formGroupName]="i" class="adt-table__row">
                    <div class="flex2">
                        {{ activityTypeTimeslotsRows[i].displayName }}
                    </div>
                    <div class="flex1">
                        {{ activityTypeTimeslotsRows[i].startTime }}
                    </div>
                    <div class="flex1">
                        {{ activityTypeTimeslotsRows[i].endTime }}
                    </div>
                    <div class="flex1 distribtion-cell">
                        <input 
                            formControlName="distributionTotal" 
                            type="number" 
                            min="0" 
                            class="adt-input" />
                    </div>
                </div>
            </div>
        
            <div class="adt-table__section-week">
                <div 
                    *ngFor="let group of formArray.controls; let i = index" 
                    class="adt-table__row" 
                    [formGroupName]="i"
                >
                    <div 
                        *ngFor="let control of group.get('weekDemand').controls; let j = index"
                        class="flex1 adt-table__cell-input" 
                        formArrayName="weekDemand" 
                    >
                        <input 
                            type="number" 
                            min="0" max="99" 
                            class="adt-input" 
                            [formControlName]="j" 
                            [class.invalid-input]="!control.valid"/>
                    </div>
                    <div class="flex1 adt-table__cell-input">
                        {{ weeklyTotals[i] }}
                    </div>
                </div>
            </div>

            <div class="adt-table__section-total">
                <div *ngFor="let group of formArray.controls; let i = index" class="adt-table__row">
                    <div class="flex1 adt-table__cell-input">
                        {{ periodTotal[i] }}
                    </div>
                    <div class="flex1 adt-table__cell-menu">
                        <button 
                            mat-button 
                            class="bol-button-text bol-gray icon-only small-icon"
                            [matMenuTriggerFor]="activityMenu.menu"
                            [matMenuTriggerData]="{activity: activityTypeTimeslotsRows[i]}"
                        >
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        <app-adt-activity-type-table-menu 
                            [activityTypeTimeslot]="activityTypeTimeslotsRows[i]"
                            #activityMenu>
                        </app-adt-activity-type-table-menu>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div *ngIf="(activityTypeTimeslotsRows$ | async)?.length === 0" class="no-entities-message">
        <p data-cy="entityNoEntitiesFound">
            {{ "NO-ENTITIES-FOUND" | translate: { entityName: 'activity types' | translate | lowercase } }}
        </p>
    </div>
</div>

<div *ngIf="(autoSaveLoadingStateSubject | async) === true && (navigateToWeekLoadingStateSubject | async) === true" class="overlay-spinner">
    <mat-spinner diameter="100"></mat-spinner>
</div>