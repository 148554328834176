<ng-container>
    <div class="bol-modal__header">
        <span class="modal-title">{{ "ACTIVITY-DEMAND.EDIT-TEMPLATES.PREFERRED-RESOURCE.TITLE" | translate }} {{ activityType.displayName }}</span>
        <div class="bol-modal__close" (click)="onCloseModal()">
        <i class="material-icons">close</i>
        </div>
    </div>
    
    <mat-dialog-content>
        <div>
            <div class="top-filters">

                <div class="demand-amount-info-message" data-cy="demandAmount">
                    <h4 class="bol-text bol-text-medium">{{ "ACTIVITY-DEMAND.EDIT-TEMPLATES.PREFERRED-RESOURCE.DEMAND-AMOUNT" | translate}}{{ demandAmount }}</h4>
                </div>
                
                <p data-cy="validResourceTypes">
                    {{ "ACTIVITY-DEMAND.EDIT-TEMPLATES.PREFERRED-RESOURCE.VALID-RESOUCETYPES" | translate}} {{ activityType.resourceTypeNames }}
                </p>

                <div class="org-units-preferred-resources-container">
                    <div class="organization-units default-mat-form-field">
                        <app-multiselect-tree
                            searchFieldPlaceholder="Search organization unit"
                            multiselectTreePlaceholder="Organization units"
                            [entities]="organizationUnitsForFiltering"
                            [preSelectedEntities]="preselectedOrganizationUnits$ | async"
                            [showSelectAll]="true"
                            [required]="false"
                            (selectedEntityIdsChanged)="onFilteredOrganizationsChanged($event)"
                            data-cy="organizationUnitsFilter"
                        >
                        </app-multiselect-tree>
                    </div>
                    <div class="preferred-resources default-mat-form-field">
                        <app-multiselect-tree
                            searchFieldPlaceholder="Search preferred resources"
                            multiselectTreePlaceholder="ACTIVITY-DEMAND.EDIT-TEMPLATES.PREFERRED-RESOURCE.PREFERRED-RESOURCES"
                            [entities]="validResources$ | async"
                            [preSelectedEntities]="preselectedPreferredResources$ | async"
                            [showSelectAll]="true"
                            [required]="false"
                            (selectedEntityIdsChanged)="onPreferredResourcesChanged($event)"
                            data-cy="preferredResourcesFilter"
                        >
                        </app-multiselect-tree>
                    </div>
                </div>
            </div>
            
            <div class="space-callout-message">
                <ng-container *ngIf="(demandExceeded$ | async) === true">
                    <bol-callout [type]="'error'" class="callout-message">{{ "ACTIVITY-DEMAND.EDIT-TEMPLATES.PREFERRED-RESOURCE.DEMAND-EXCEEDED" | translate }}</bol-callout>
                </ng-container>
            </div>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions>
        <div class="dialog-actions-buttons">
            <button 
                mat-button 
                (click)="onCancel()"
                data-cy="preferredResourceDialogConfirmButton"
            >   
                <span translate>ACTION.CANCEL</span>
            </button>
        </div>
        <div class="dialog-actions-buttons">
            <button 
                mat-button 
                class="bol-button-solid"
                [disabled]="(demandExceeded$ | async) === true"
                (click)="onConfirm()"
                data-cy="preferredResourceDialogConfirmButton"
            >
                <span translate>ACTION.SAVE</span>
            </button>
        </div>
    </mat-dialog-actions>
</ng-container>
